const lt = {
  nav: {
    login: "Prisijungti",
    home: "Pagrindinis meniu",
    admins: "Administratoriai",
    events: "Renginiai",
    documents: "Dokumentai",
    visitors: "Lankytojai",
    partners: "Partneriai",
    duk: "DUK",
    information: "Informacija",
    catering: "Maitinimas",
    contacts: "Kontaktai, soc. tinklai",
    notificationSend: "Pranešimo išsiuntimas",
    news: "Naujienos",
    privacyPolicy: "Privatumo politika",
    rules: "Taisyklės",
    tualets: "Tualetai",
    bars: "Barai",
    entrances: "Įėjimai",
    parking: "Aikštelės",
    premiumLounge: "Premium Lounge",
    vipLodge: "VIP ložės",
    termsAndConditions: "Taisyklės ir sąlygos",
    sectors: "Sektoriai/Ložės",
    banners: "Reklamjuostės",
    qrLinks: "QR nuorodos",
  },
  labels: {
    email: "El. paštas",
    createdAt: "Sukūrimo data",
    edit: "Redaguoti",
    create: "Sukurti",
    confirm: "Patvirtinti",
    header: "Antraštė",
    subHeader: "Paantraštė",
    button: "Mygtukas",
    dropOrSelect: "Nuvilkite arba pasirinkite nuotrauką",
    status: "Būsena",
    date: "Data",
    title: "Pavadinimas",
    newsEmail: "Rinkodaros pranešimai",
    name: "Vardas",
    active: "Aktyvus",
    notActive: "Neaktyvus",
    blocked: "Užblokuotas",
    emailNotVerified: "El. paštas nepatvirtintas",
    unBlocked: "Atblokuotas",
    yes: "Taip",
    no: "Ne",
    all: "Visi",
    raised: "Iškeltas",
    notRaised: "Neiškeltas",
    signInProvider: "Prisijungimo būdas",
    phoneNumber: "Telefono numeris",
    youtube: "Youtube kanalo ID",
    facebook: "Facebook vardas",
    instagram: "Instagram vardas",
    workingHours: "Darbo laikas",
    address: "Adresas",
    number: "Nr.",
    logo: "Logotipas",
    url: "Nuoroda",
    language: "Kalba",
    message: "Žinutė",
    screen: "Langas",
    home: "Namai",
    food: "Maistas",
    arena: "Arena",
    events: "Renginiai",
    profile: "Profilis",
    languageEn: "Anglų",
    languageLt: "Lietuvių",
    action: "Veiksmas",
    notRequired: "neprivaloma",
    image: "Paveikslėlis",
    updatedAt: "Atnaujinimo data",
    description: "Aprašymas",
    placeInArena: "Vieta arenoje",
    entranceToArena: "Įėjimas į areną",
    workTime: "Darbo laikas",
    assortiment: "Asortimentas",
    payment: "Mokėjimas",
    coordinates: "Vietos koordinatės",
    coordinatesArena: "Vieta arenoje",
    registerDate: "Registracijos data",
    phoneNr: "Tel. nr.",
    label: "Pavadinimą",
    table: "Staliuką",
    floor: "Aukštą",
    tableGreet: "Staliukas (Greet)",
    information: "Informacija",
    quantity: "Kiekis",
    guid: "GUID",
    sectorLodge: "Sektorius/Ložė",
    assigned: "Priskirta",
    notAssigned: "Nepriskirta",
  },
  titles: {
    search: "Paieška",
    edit: "Redaguoti",
    add: "Pridėti",
    email: "El. pašto adresas",
    password: "Slaptažodis",
    changePassword: "Keisti slaptažodį",
    bannerStatus: "Reklamjuostės būsena",
    showBanner: "Rodyti maitinimo reklamjuostę",
    photo: "Nuotrauka",
    noData: "Nėra duomenų",
    filterByName: "Ieškoti pagal pavadinimą",
    filterByTitleAndLabel: "Ieškoti pagal pavadinimą ir antraštę",
    filterByNameAndEmail: "Ieškoti pagal vardą ir el. paštą",
    filterSectors: "Ieškoti pagal aukštą, pavadinimą ir staliuką",
    filterQrLinks: "Ieškoti pagal GUID ir sektorių/ložę",
    all: "Visi",
    raised: "Iškeltas",
    notRaised: "Neiškeltas",
    rowsPerPage: "Eilučių puslapyje",
    block: "Užblokuoti",
    unBlock: "Atblokuoti",
    contacts: "Kontaktai",
    socialNetworks: "Socialiniai tinklai",
    changeOrder: "Keisti eilės tvarką",
    addPartner: "Pridėti partnerį",
    languageEn: "Anglų kalba",
    languageLt: "Lietuvių kalba",
    searchPartner: "Ieškoti pagal pavadinimą ir nuorodą",
    turnOnNews: "Įjungti rinkodaros pranešimus",
    turnOffNews: "Išjungti rinkodaros pranešimus",
    turnedOn: "įjungtas",
    turnedOff: "išjungtas",
    outOf: "iš",
    writeText: "Įveskite tekstą",
    tables: "Staliukai",
    label: "Pavadinimas",
    table: "Staliukas",
    floor: "Aukštas",
    restaurant: "Restoranas",
    tableNotChosen: "Staliukas nepasirinktas",
    qrCodeScanning: "QR kodo skenavimas",
    showHomeBanner: "Rodyti reklamjuostę",
    guidGeneration: "GUID generavimas",
    generatedGuid: "Sugeneruoti GUID",
    unnamed: "Be pavadinimo",
    rowField: "Eilė (neprivaloma)",
    numberField: "Vieta (neprivaloma)",
    row: "Eilė",
    number: "Vieta",
    guidEditAssign: "GUID redagavimas ir priskyrimas",
    generatedGuids: "Sugeneruoti GUID",
    seatPrefix: {
      sectors: "Sektorius",
      lodges: "Ložė",
      parter: "Parteris",
    },
  },
  buttons: {
    login: "Prisijungti",
    logout: "Atsijungti",
    addAdmin: "Pridėti administratorių",
    editAdmin: "Redaguoti administratorių",
    clear: "Išvalyti",
    delete: "Pašalinti",
    edit: "Redaguoti",
    cancel: "Atšaukti",
    ok: "Gerai",
    addImage: "Įkelti paveikslėlį",
    save: "Išsaugoti",
    exportData: "Eksportuoti duomenis",
    block: "Užblokuoti",
    unBlock: "Atblokuoti",
    raise: "Iškelti",
    notRaise: "Neiškelti",
    confirm: "Patvirtinti",
    all: "Visi",
    agreementForNew: "Davę sutikimą rinkodaros pranešimams",
    contacts: "Kontaktai",
    socialNetworks: "Socialiniai tinklai",
    close: "Uždaryti",
    send: "Siųsti",
    turnOn: "Įjungti",
    turnOff: "Išjungti",
    add: "Pridėti",
    addNew: "Pridėti naujieną",
    addAsNew: "Pridėti naują",
    syncTables: "Sinchronizuoti staliukus",
    downloadQrCodes: "Atsisiųsti visus QR kodus (8x8 cm)",
    downloadQrCodes4x4: "Atsisiųsti visus QR kodus (4x4 cm)",
    downloadQrCode: "Atsisiųsti QR kodą (8x8 cm)",
    downloadQrCode4x4: "Atsisiųsti QR kodą (4x4 cm)",
    active: "Aktyvuoti",
    inactive: "Deaktyvuoti",
    changeTable: "Keisti staliuką",
    selectPart: "Pasirinkite dalį",
    downloadPart: "Atsisiųsti dalį",
    showQrCodeButton: "Rodyti QR kodo skenavimo mygtuką",
    deleteFromAdmins: "Pašalinti iš administratorių",
    generateGuid: "Generuoti GUID",
    generate: "Generuoti",
    editAndAssign: "Redaguoti ir priskirti",
  },
  errors: {
    auth: {
      userNotFound: "Vartotojas nerastas",
      wrongPassword: "Neteisingas slaptažodis",
      invalidPassword: "Slaptažodis turi būti sudarytas iš bent 6 simbolių",
      emailAlreadyExists: "Vartotojas su tokiu el. pašto adresu jau egzistuoja",
      networkRequestFailed: "Nepavyko prisijungti, patikrinkite interneto ryšį",
      notAdmin: "Vartotojas nėra administratorius",
    },
    enterAction: "Pasirinkite veiksmą",
    enterWindow: "Pasirinkite langą",
    enterPassword: "Įveskite slaptažodį",
    enterEmail: "Įveskite el. pašto adresą",
    invalidEmail: "Neteisingas el. pašto adresas",
    error: "Įvyko klaida, bandykite dar kartą",
    enterHeader: "Įveskite antraštę",
    enterSubHeader: "Įveskite paantraštę",
    enterButton: "Įveskite mygtuko tekstą",
    selectPhoto: "Pasirinkite nuotrauką",
    enterPhoneNumber: "Įveskite telefono numerį",
    invalidPhoneNumber: "Neteisingas telefono numeris",
    enterWorkingHours: "Įveskite darbo laiką",
    enterAddress: "Įveskite adresą",
    enterTitle: "Įveskite pavadinimą",
    enterUrl: "Įveskite nuorodą",
    enterLogo: "Pasirinkite logotipą",
    enterMessage: "Įveskite žinutę",
    chooseLanguage: "Pasirinkite kalbą",
    chooseAction: "Pasirinkite veiksmą",
    enterLink: "Įveskite nuorodą",
    chooseScreen: "Pasirinkite langą",
    enterLabel: "Įveskite antraštę",
    enterDescription: "Įveskite aprašymą",
    enterPlaceInArena: "Įveskite vietą arenoje",
    enterEntranceToArena: "Įveskite įėjimą į areną",
    enterWorkTime: "Įveskite darbo laiką",
    enterAssortiment: "Įveskite asortimentą",
    enterPayment: "Įveskite mokėjimo būdą",
    enterQuantity: "Įveskite kiekį",
    maxQuantity: "Kiekis negali viršyti 10000",
  },
  messages: {
    successFullyCreated: "Sėkmingai sukurta",
    successFullyChanged: "Sėkmingai pakeista",
    successFullyAdded: "Sėkmingai pridėta",
    successFullyUpdated: "Sėkmingai atnaujinta",
    successFullyDeleted: "Sėkmingai ištrinta",
    changeOrdering: "Norėdami keisti eilės tvarką tempkite aukštyn arba žemyn",
    successFullyNotificationSent: "Pranešimas sėkmingai išsiųstas",
    successfullySynced: "Staliukai sėkmingai sinchronizuoti",
    successfullyDownloaded: "Sėkmingai atsiųsta",
    successfullyChanged: "Sėkmingai pakeista",
    tryAgain: "Klaida, bandykite dar kartą",
    generatingPleaseWait:
      "Generavimas gali užtrukti kelias minutes. Neuždarykite puslapio.",
  },
  descriptions: {
    deleteAdmin:
      "Paskyros <bold>{{ email }}</bold> rolė bus pakeista į lankytojo. Ar tikrai norite tęsti?",
    deleteVisitor:
      "Lankytojas <bold>{{ name }}</bold> bus ištrintas. Ar tikrai norite tęsti?",
    blockVisitor:
      "Lankytojas <bold>{{ name }}</bold> bus {{status}}. Ar tikrai norite tęsti?",
    raiseVisitor:
      "Renginys <bold>{{ name }}</bold> bus {{status}}. Ar tikrai norite tęsti?",
    activeSector:
      "Sektorius/Ložė <bold>{{ name }}</bold> bus {{status}}. Ar tikrai norite tęsti?",
    deletePartner:
      "Partneris <bold>{{ name }}</bold> bus ištrintas. Ar tikrai norite tęsti?",
    deleteDuk:
      "Klausimas <bold>{{ name }}</bold> bus ištrintas. Ar tikrai norite tęsti?",
    deleteNew:
      "Naujiena <bold>{{ name }}</bold> bus ištrintas. Ar tikrai norite tęsti?",
    turnOffNews:
      "Rinkodaros pranešimai <bold>{{ name }}</bold> bus {{status}}. Ar tikrai norite tęsti?",
    deleteInfo:
      "<bold>{{ name }}</bold> bus ištrintas. Ar tikrai norite tęsti?",
    information:
      "Paskyra su tokiu el. pašto adresu jau egzistavo. Šiai paskyrai buvo papildomai pridėta administratoriaus rolė. Slaptažodis išliko nepakeistas.",
    welcomeToTwinsbet: "Sveiki atvykę į\nTWINSBET areną",
    useTwinsBet:
      "Pasinaudokite TWINSBET arenos galimybėmis ir mėgaukitės renginiu dar labiau.",
    app: 'PROGRAMĖLĖ',
    food: "MAISTO UŽSAKYMAS",
    casino: "LOŠIMAI",
    useTheApp: "Naudokite arenos programėlę",
    foodDrinkMenu: "Maisto ir gėrimų meniu",
    allFunctions: "Visos arenos funkcijos vienoje vietoje",
    orderQuickly: "Užsisakykite patogiai ir greitai",
    findLuck: "Lažybos ir lošimų automatai",
    twinsBet: "www.twinsbet.lt",
  },
};

export default lt;
